import React from "react";
import styles from "./Experience.module.scss";
import Container from "../Container/Container";

const Experience = ({ color, lines }) => {
  return (
    <div className={styles.wrapper}>
      <Container lines={lines}>
        <div className={styles.container}>
          <div className={styles.total}>
            <div className={styles.experience}>
              <h2 className={styles.h2}>Experience</h2>
              <div className={styles.elements}>
                <div className={styles.el}>
                  <p className={styles.date}>November 2023 - Present</p>
                  <p style={{ color }} className={styles.title}>
                    Full Stack Software Engineer
                  </p>
                  <ul className={styles.desc}>
                    <li style={{ marginBottom: 4 }}>
                      Orchestrated the migration of critical components, optimizing performance and reducing codebase by 30%, resulting in enhanced efficiency.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                     Pioneered integration solutions, facilitating real-time data processing and analysis, fostering data-driven decision-making.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                      Spearheaded backend enhancements, leveraging modern technologies to achieve a 30% improvement in response time and scalability.
                    </li>
                    <li>
                      Advocated for a culture of quality and collaboration, fostering knowledge sharing and alignment with strategic objectives through regular communication with key stakeholders.
                    </li>
                  </ul>
                </div>
                <div className={styles.el}>
                  <p className={styles.date}>August 2021 - November 2023</p>
                  <p style={{ color }} className={styles.title}>
                    Software Engineer | Co-Founder
                  </p>
                  <ul className={styles.desc}>
                    <li style={{ marginBottom: 4 }}>
                      Spearheaded a diverse team of 4 professionals, leveraging their unique talents to deliver over 15 projects punctually and with excellence.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                     Directed 3 startup ventures, overseeing a total of 13 developers, fostering a culture of rigorous code review and seamless collaboration.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                     Demonstrated proficiency across various tech stacks, including frontend, backend, and mobile app development, ensuring comprehensive project coverage and adaptability.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                     Provided invaluable insights and guidance on UI/UX design elements, enhancing user experience and optimizing project outcomes.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                     Revamped Restful APIs, achieving an 147ms reduction in average response time, optimizing performance and enhancing user satisfaction.
                    </li>
                    <li>
                      Led the refactoring of Legacy React.js codebases, resulting in a remarkable 4-second reduction in loading time, validated by Lighthouse metrics.
                    </li>
                  </ul>
                </div>
                <div className={styles.el}>
                  <p className={styles.date}>Oct 2019 - Nov 2021</p>
                  <p style={{ color }} className={styles.title}>
                    Music Composer & Producer
                  </p>
                  <ul className={styles.desc}>
                    <li style={{ marginBottom: 4 }}>
                      Crafted captivating musical compositions for over 200 global projects, ranging from games to films and advertisements, earning acclaim for versatility across diverse genres.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                      Engineered original arrangements and immersive sound designs for cinematic endeavors, enhancing audience immersion and driving increased engagement.
                    </li>
                    <li>
                      Fostered collaboration with a multicultural clientele, achieving an impressive 95% satisfaction rate across a wide spectrum of projects and musical styles.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.education}>
              <h2 className={styles.h2}>Education</h2>
              <div className={styles.elements}>
                <div className={styles.el}>
                  <p className={styles.date}>2013 - 2017</p>
                  <p style={{ color }} className={styles.title}>
                    Bachelor's Degree - Civil Engineering
                  </p>
                </div>
                <div className={styles.el}>
                  <p className={styles.date}>2023</p>
                  <p style={{ color }} className={styles.title}>
                    Meta Back-End Developer - Specialization Certificate
                  </p>
                  <a
                    href="https://coursera.org/verify/professional-cert/Z6TB3ECAUQF8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="./images/meta-backend.webp"
                      width={"70px"}
                      alt="Meta Backend"
                    />
                  </a>
                </div>
                <div className={styles.el}>
                  <p className={styles.date}>2023</p>
                  <p style={{ color }} className={styles.title}>
                    Google Project Management - Specialization Certificate
                  </p>
                  <a
                    href="https://coursera.org/verify/professional-cert/PNU8MXQ4EYXL"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="./images/meta-backend.webp"
                      width={"70px"}
                      alt="Meta Backend"
                    />
                  </a>
                </div>
                <div className={styles.el}>
                  <p className={styles.date}>2023</p>
                  <p style={{ color }} className={styles.title}>
                    Google Digital Marketing & E-commerce - Specialization
                    Certificate
                  </p>
                  <a
                    href="https://coursera.org/verify/professional-cert/RYJESECUVKGP"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="./images/meta-backend.webp"
                      width={"70px"}
                      alt="Meta Backend"
                    />
                  </a>
                </div>

                <div className={styles.el}>
                  <p className={styles.date}>2023</p>
                  <p style={{ color }} className={styles.title}>
                    Foundations of User Experience (UX) Design - Course
                    Certificate
                  </p>
                  <a
                    href="https://coursera.org/verify/P6XQQCRAZR6D"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="./images/google-ui.webp"
                      width={"70px"}
                      alt="google-ui"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      ;
    </div>
  );
};

export default Experience;
